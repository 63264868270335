<template>
  <!-- 传感器管理界面 -->
  <div class="create-screen">
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <div class="table-layout">
        <el-row :gutter="20">
          <el-form
            ref="sensorForm"
            :model="sensorForm"
            label-width="100px"
            :inline="true"
          >
            <el-row class="btnRow">
              <el-col :span="5" class="elrow">
                <el-form-item
                  label="唯一编码"
                  prop="iditifyId"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="sensorForm.iditifyId"
                    clearable
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="elrow">
                <el-form-item label="传感器类型" prop="xslx">
                  <el-select v-model="sensorForm.type" style="width: 160px">
                    <el-option
                      v-for="item in typeoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="elrow">
                <el-form-item label="在线状态" prop="xsxz">
                  <el-select v-model="sensorForm.status" style="width: 160px">
                    <el-option
                      v-for="item in onlineOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <search-button
                v-show="showAll"
                :showAll="showAll"
                @clearAll="clearAll"
                @exportEvent="exportEvent"
                @importEvent="importEvent"
                @searchEvent="searchSenser"
                @closepop="closepop"
              ></search-button>
            </el-row>
            <el-row :class="!showAll ? 'btnRow' : 'unBtnRow'">
              <el-col :span="5" :class="!showAll ? 'btnRow' : 'unBtnRow'">
                <el-form-item
                  label="规格型号"
                  prop="xinghao"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="sensorForm.xinghao"
                    size="mini"
                    clearable
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="elrow">
                <el-form-item label="上传状态" prop="xslx">
                  <el-select
                    v-model="sensorForm.uploadStatus"
                    size="mini"
                    style="width: 160px"
                  >
                    <el-option
                      v-for="item in uploadoptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <SearchButton
                v-show="!showAll"
                :showAll="showAll"
                @clearAll="clearAll"
                @exportEvent="exportEvent"
                @importEvent="importEvent"
                @searchEvent="searchSenser"
                @closepop="closepop"
              ></SearchButton>
            </el-row>
          </el-form>
        </el-row>
        <br />

        <el-row style="margin-bottom: 20px">
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              icon="el-icon-plus"
              v-if="hasPermission('add')"
              size="mini"
              class="editBtn"
              @click="addVisiableSensor"
              >新增</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              v-if="hasPermission('edit')"
              size="mini"
              class="editBtn"
              @click="editSensor"
              >修改</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              class="deleteBtn"
              icon="el-icon-delete"
              v-if="hasPermission('delete')"
              size="mini"
              @click="delSensor"
              >删除</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              v-if="hasPermission('bangding')"
              @click="bangdingSensor"
              >绑定</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="yList"
          :cell-style="{ 'text-align': 'center' }"
          :row-style="getRowClass"
          :header-row-style="getRowClass"
          :header-cell-style="getRowClass"
          @selection-change="handleSelectionChangeNode"
          border
          style="width: 100%;"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column min-width="4%" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column min-width="10%" label="传感器类型id" v-if="false"
            ><template slot-scope="scope">{{
              scope.row.sensor_id
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="分类编码"
            ><template slot-scope="scope">{{
              scope.row.collection_terminal_type
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="传感器编号"
            ><template slot-scope="scope">{{
              scope.row.sensor_num
            }}</template></el-table-column
          >
          <el-table-column min-width="11%" label="位置"
            ><template slot-scope="scope">{{
              scope.row.sensor_position | getFilters
            }}</template></el-table-column
          >
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="pageSize"
            layout="prev,pager,next,total,jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
      <!--上传文件导入文件弹窗-->
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="centerDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="150px"
              :model="sensorForm"
            >
              <el-form-item label="类型:" :required="true" prop="entity">
                <!-- <el-select
                  v-model="sensorForm.entity"
                  :popper-append-to-body="false"
                  clearable
                  allow-create
                  filterable
                  default-first-option
                  ><el-option
                    v-for="item in typeSetOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="上传xlsx文件:">
                <el-upload
                  ref="upload"
                  action="#"
                  :auto-upload="false"
                  :multiple="false"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  class="upload-demo uploadImages"
                  :http-request="uploadHttpRequest"
                  :on-remove="handleRemove"
                  :on-change="handleChange"
                >
                  <el-button slot="trigger" class="btn-text" type="primary"
                    >选取文件</el-button
                  >
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="btn-text" @click="submitUpload"
                  >上传</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
      <!--新增单条设备信息管理-->
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="singleAddDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="openDigl">
            <div class="item1">
              <br />
              <el-form
                label-position="left"
                label-width="130px"
                :model="sensorForm"
              >
                <el-form-item label="传感器id:" :required="true" v-if="false">
                  <el-input
                    class="btn-text"
                    v-model="sensorForm.sensor_id"
                  ></el-input>
                </el-form-item>
                <el-form-item label="传感器编号:" :required="true">
                  <el-input
                    class="btn-text"
                    v-model="sensorForm.sensor_num"
                  ></el-input>
                </el-form-item>
                <el-form-item label="出场编码:" :required="true">
                  <el-input
                    class="btn-text"
                    v-model="sensorForm.exit_code"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item label="传感器类型:" :required="true">
                  <el-select
                    v-model="sensorForm.collection_terminal_type"
                    :popper-append-to-body="false"
                    clearable
                    allow-create
                    filterable
                    default-first-option
                    ><el-option
                      v-for="item in typeSetOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item>
                  <el-button
                    type="primary"
                    class="btn-text"
                    @click="handleClick"
                    >{{ addorEdittext }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 5、绑定区域-->
    <!--绑定弹窗需要加载用户所需能看到的菜单或者路由-->
    <div>
      <el-dialog
        title="绑定传感器"
        :append-to-body="true"
        :visible.sync="shouquanDialogVisiable"
        custom-class="custom-dialog"
        width="40%"
        center
      >
        <el-form>
          <el-form-item label="传感器类型:" :required="true">
            <el-select
              v-model="abbInfo.collection_terminal_type"
              :popper-append-to-body="false"
              ><el-option
                v-for="item in typeSetOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="传感器位置:" :required="true">
            <el-select
              v-model="abbInfo.sensor_position"
              :popper-append-to-body="false"
              clearable
              allow-create
              filterable
              default-first-option
              ><el-option
                v-for="item in positionArr"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-text" @click="shouquanEvent"
              >绑定</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getdicData,
  addsensor,
  delsensor,
  editsensorById,
  getAllsensor,
  addSensorInfo
} from "@/api/index";
import SearchButton from "./tool/button.vue";
export default {
  data() {
    return {
      fullscreenLoading: false,
      pageSize: 10,
      currentPage1: 1,
      selctionNodeRows: [],
      nodeids: [],
      centerDialogVisible: false,
      singleAddDialogVisible: false,
      addorEdittext: "新增",
      shouquanDialogVisiable: false,
      addorEditshoquan: "绑定",
      fileList: [],
      tableData: [],
      originTableData: [],
      yList: [],
      abbInfo: {
        collection_terminal_type: "",
        sensor_position: "",
      },
      //查询条件form
      sensorForm: {
        sensor_id: "",
        sensor_num: "",
        exit_code: "",
        // collection_terminal_type: "",
      },
      typeSetOptions: [
        { value: "jftev", label: "UbiCon-TEV" },
        { value: "jfhf-ae", label: "UbiCon-HFCT/AE" },
        { value: "jf3in1", label: "UbiCon-TEV/AE/UHF" },
        { value: "jftev-ae", label: "UbiCon-TEV/AE" },
      ],
      positionArr: [],
      typeoptions: [
        { id: "1", name: "tev" },
        { id: "2", name: "ae" },
        { id: "3", name: "hfct" },
        { id: "4", name: "tem" },
      ],
      onlineOptions: [
        { label: "在线", value: "1" },
        { label: "离线", value: "2" },
        { label: "故障", value: "3" },
      ],
      uploadoptions: [
        { id: "1", name: "正常" },
        { id: "2", name: "异常" },
        { id: "3", name: "中断" },
      ],
      showAll: true,
    };
  },
  components: {
    SearchButton,
  },
  computed: {
    tableHeight() {
      if (this.showAll) {
        return window.innerHeight - 140;
      } else {
        return window.innerHeight - 200;
      }
    },
  },
  mounted() {
    this.getSensorType();
  },
  filters: {
    getFilters: (value) =>{
        return value
    }
  },
  methods: {
    // 加载表格数据
    getSensorType() {
      getAllsensor()
        .then((res) => {
          // let res = {
          //   code: 200,
          //   data: [
          //     {
          //       sensor_id: "5",
          //       sensor_num: "20013_t",
          //       exit_code: "1",
          //       manufacturer_id: "1",
          //       sensor_type_id: "1",
          //       establish_time: "2024-05-22 07:15:26.141374+00:00",
          //       sensor_type: "t",
          //       manufacturer: "manufacturer",
          //       kgg: [],
          //     },
          //     {
          //       sensor_id: "6",
          //       sensor_num: "20013_e",
          //       exit_code: "1",
          //       manufacturer_id: "1",
          //       sensor_type_id: "2",
          //       establish_time: "2024-05-22 08:16:41.371265+00:00",
          //       sensor_type: "e",
          //       manufacturer: "manufacturer",
          //       kgg: [],
          //     },
          //     {
          //       sensor_id: "7",
          //       sensor_num: "20013_u",
          //       exit_code: "1",
          //       manufacturer_id: "1",
          //       sensor_type_id: "3",
          //       establish_time: "2024-05-22 08:17:00.680737+00:00",
          //       sensor_type: "u",
          //       manufacturer: "manufacturer",
          //       kgg: [],
          //     },
          //     {
          //       sensor_id: "8",
          //       sensor_num: "20012_t",
          //       exit_code: "1",
          //       manufacturer_id: "1",
          //       sensor_type_id: "1",
          //       establish_time: "2024-05-23 07:31:19.229760+00:00",
          //       sensor_type: "t",
          //       manufacturer: "manufacturer",
          //       kgg: [],
          //     },
          //   ],
          // };
          if (res.code == 200) {
            this.tableData = res.data;
            //   this.tableData = sortBy(this.tableData, ["upload_time"]).reverse();
            this.originTableData = this.tableData;
            this.yList = this.tableData.slice(
              (this.currentPage1 - 1) * this.pageSize,
              this.currentPage1 * this.pageSize
            );
          } else {
            this.$message.error(res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    filtersType(val) {
      if (val == "jf3in1") {
        return "UbiCon-TEV/AE/UHF";
      } else if (val == "jfhf-ae") {
        return "UbiCon-HFCT/AE";
      } else if (val == "jftev") {
        return "UbiCon-TEV";
      } else if (val == "jftev-ae") {
        return "UbiCon-TEV/AE";
      } else if (val == "tem") {
        return "温度";
      }
    },
    hasPermission(permissionName) {
      let elementUi = [];
      let arr = this.formatTree(this.$store.getters.permissions, "0");
      arr.forEach((v) => {
        if (v.attribute_name == "台账管理") {
          v.children.forEach((m) => {
            if (m.attribute_name == "传感器管理") {
              m.children.forEach((j) => {
                elementUi.push(j.remarks);
              });
            }
          });
        }
      });
      return elementUi.includes(permissionName);
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 文件上传之前的操作
    beforeUpload(file) {
      // 文件大小限制为20M
      const fileLimit = file.size / 1024 / 1024 < 2000;
      if (!fileLimit) {
        console.log("上传文件大小不超过2000M！");
      }
      // 返回判断条件,false停止上传
      // return fileType && fileLimit;
      return fileLimit;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 新增设备弹窗信息
    addVisiableSensor() {
      this.sensorForm = {
        sensor_id: "",
        sensor_num: "",
        exit_code: "",
        // collection_terminal_type: "",
      };
      this.singleAddDialogVisible = true;
      this.addorEdittext = "新增";
    },
    // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    uploadHttpRequest(param) {
      // FormData对象
      let formData = new FormData();
      formData.append("file", param.file);
      // formData.append("entity", this.nodeForm.entity);
      uploadFormData(formData)
        .then((res) => {
          var msg = "";
          if (res.code === 200) {
            this.$message.success("上传成功");
            // this.nodeForm.entity = "";
            this.fileList = [];
            this.centerDialogVisible = false;
            // this.getTableData();
          } else {
            msg = res.error;
            this.$message.error("上传失败！原因是" + msg);
            this.fileList = [];
          }
        })
        .catch((err) => {
          this.$refs.upload.clearFiles();
          this.fileList = [];
        });
    },
    // 上传文件
    submitUpload() {
      // 判断是否选择了文件，若没有，则后边支持导入
      if (this.fileList.length < 1) {
        this.$message.warning("请选择上传的文件！");
      } else {
        // 显示散点图谱
        this.centerDialogVisible = false;
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      }
    },
    // 新增传感器信息
    addSensor() {
      addsensor(this.sensorForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getSensorType();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.sensorForm.sensor_id = this.selctionNodeRows[0].sensor_id;
        this.sensorForm.sensor_num = this.selctionNodeRows[0].sensor_num;
        this.sensorForm.exit_code = this.selctionNodeRows[0].exit_code;
        // this.sensorForm.collection_terminal_type =
        //   this.selctionNodeRows[0].collection_terminal_type;
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个传感器
    delSensor() {
      if (this.nodeids.length == 0) {
        this.$message.warning("请至少选择一条数据进行删除操作！");
      } else {
        delsensor({
          sensor_id: this.selctionNodeRows[0].sensor_id,
        })
          .then((res) => {
            // let res = { code: 200 };
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getSensorType();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    // 绑定传感器相关
    bangdingSensor() {
      // 打开弹窗 绑定的弹窗加载设备列表，选中一个设备绑定即可
      // 加载传感器相关信息表
      getdicData().then((res) => {
        if (res.code == 200) {
          let typeSetOptions = [], positionArr = []
          console.log(res.data,'弹窗里面的options数据');
          
          if(res.data.length>0){
            res.data.forEach(v=>{
                if(v.explain == "sensor_position"){
                    v.Tripartite_Parameters.forEach(m=>{
                      positionArr.push({
                        label: m.tripartite_parameters,
                        value: m.tripartite_parameters_explain
                      })
                    })
                } 
                if(v.explain == "collection_terminal_type"){
                    v.Tripartite_Parameters.forEach(m=>{
                      typeSetOptions.push({
                        label: m.tripartite_parameters,
                        value: m.tripartite_parameters_explain
                      })
                    })
                }
            })
          }
          this.typeSetOptions = typeSetOptions
          this.positionArr = positionArr
        }
      });
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.abbInfo.sensor_position =  this.selctionNodeRows[0].sensor_position
        this.abbInfo.collection_terminal_type =  this.selctionNodeRows[0].collection_terminal_type
        this.shouquanDialogVisiable = true;
        // 判断一下，如果有过绑定的话，那么就改成修改绑定；如果没有绑定的话，还是绑定；通过查询绑定结果得到的结果不为空
        // getdicDataByExplain(this.selctionNodeRows[0].explain)
        //   .then((res) => {
        //     if (res.code == 200) {
        //       if (res.data.length > 0) {
        //         res.data.forEach((v) => {
        //           this.defaultKey.push(v.tripartite_parameters_id);
        //         });
        //         console.log(this.defaultKey);
        //         this.shouquanDialogVisiable = true;
        //         this.addorEditshoquan = "修改绑定";
        //         // 如果有可能的话，这块需要把绑定赋值过去，以便用户修改
        //       } else {
        //         this.shouquanDialogVisiable = true;
        //         this.addorEditshoquan = "绑定";
        //         // 这里就是加载所有权限列表，以供用户在列表中单选
        //       }
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      }
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:#3f5c6d2c;text-align:center";
    },
    handleSizeChange1(val) {
      this.currentPage1 = 1;
      this.pageSize = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改变电站
    relationEdit() {
      editsensorById(this.sensorForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          this.getSensorType();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    editBtn(row) {
      console.log(row);
    },
    deleteBtn(row) {
      console.log(row);
    },
    closepop() {
      this.showAll = !this.showAll;
    },
    searchSenser() {
      console.log("您进入了父组件，点击了查询之后可以得到：" + this.sensorForm);
    },
    shouquanEvent() {
      // 绑定事件
      let params = {
        sensor_id: "",
        sensor_position: this.abbInfo.sensor_position,
        collection_terminal_type: this.abbInfo.collection_terminal_type
      }
      params.sensor_id = this.selctionNodeRows[0].sensor_id
      addSensorInfo(params).then(res=>{
        if (res.code == 200) {
          this.$message.success("绑定成功！");
          this.shouquanDialogVisiable = false
          this.getSensorType();
        } else {
          this.$message.error(res.error);
        }
      })
    },
    // 重置
    clearAll() {},
    importEvent() {
      // 打开文件导入的弹窗
      this.centerDialogVisible = true;
    },
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.btnRow {
  display: block;
}

.unBtnRow {
  display: none;
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
</style>
